
/**
 * ¡IMPORTANTE!
 *
 * Este archivo es autogenerado por el script "version.js", no
 * modificarlo manualmente, ni pushearlo al repo.
 *
 */

export const VERSION = {
    "dirty": false,
    "raw": "922204cd0",
    "hash": "922204cd0",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "922204cd0",
    "semverString": null,
    "version": "2.21.3"
};

