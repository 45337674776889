import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { UserFields, User } from '../../shared/models';
import { BzStorageService } from '@balanz/services';
import acl from '../../shared/constants/acl.json';
import { Permission, UserPermissions } from './../../shared/models/user-permissions.model';
import { EnvironmentService } from './environment.service';
import { VERSION } from 'src/environments/version';
import { UAParser } from 'ua-parser-js';

export interface PostCambiarUsuario {
    campo: string;
    passActual: string;
    user: string;
    valor: string;
}

export interface PostCambiarEmail {
    LlamadaVoz: number;
    campo: string;
    passActual: string;
    user: string;
    valor: string;
}

export interface AuthPermission {
    id: string;
    texto: string;
    path: string;
    opciones: { [key: string]: any };
}

export interface AuthSSO {
    idAplicacion: string;
    path: string;
}

export const AuthServiceFactory = (as: AuthService) => () => as.initWindowsPlatformVersion();

@Injectable()
export class AuthService {
    private _basePath = this._envService.env.BaseUrlService;
    private _userSubject$: BehaviorSubject<User>;
    private _permissions = [];
    private _source = this._envService.env.requestsSource;
    private _majorPlatformVersion = -1;

    get userPermissions(): UserPermissions {
        if (this._userSubject$ && this._userSubject$.value) {
            return this._userSubject$.value.Permisos.find(p => p.id === 'transaccional')?.menu;
        }
        return [];
    }

    get mainPermissions(): Permission[] {
        if (this._userSubject$ && this._userSubject$.value) {
            return this._userSubject$.value.Permisos;
        }
        return [];
    }

    constructor(
        private _http: HttpClient,
        private _storageService: BzStorageService,
        private _envService: EnvironmentService
    ) {
        if (this._envService.env.isCustomerImpersonate) {
            const auth = this._storageService.get('asesores-v2_auth', false, true);
            if (auth) {
                this._storageService.set('auth', auth);
            }
        } else {
            /* MIGRACION DEL STORAGE, BORRAR AL TERMINAR */
            const auth = this._storageService.get('auth', false, true);
            if (auth) {
                this._storageService.set('auth', auth);
                this._storageService.remove('auth', false, true);
            }
            /* MIGRACION DEL STORAGE, BORRAR AL TERMINAR */
        }
        this._userSubject$ = new BehaviorSubject<User>(this._storageService.get('auth'));
        this._permissions = this.getPermissions();
    }

    /** Observable que informa cambios en el usuario. */
    onUserChange(): Observable<User> {
        return this._userSubject$.asObservable();
    }

    /** Obtiene el usuario actual */
    getCurrentUser(): User {
        return this._userSubject$.value;
    }

    /** Actualiza las propiedades del usuario actual de manera local. */
    updateCurrentUser(userFields: UserFields): void {
        const currentUser = this._userSubject$.value;
        this._userSubject$.next({ ...currentUser, ...userFields });
        this._storageService.set('auth', this._userSubject$.value);
    }

    changeAvatar(newAvatar: string): void {
        if (newAvatar[0] !== '/') {
            newAvatar = '/' + newAvatar;
        }
        const userNewValue = this._userSubject$.value;
        userNewValue.Avatar = newAvatar;
        this._userSubject$.next({ ...userNewValue }); // emit new value
        this._storageService.set('auth', userNewValue); // update user session
    }

    getAccessToken(): string {
        const user = this._userSubject$.value;
        return user ? user.AccessToken : null;
    }

    preLogin$(user: string, params): Observable<any> {
        return this._http.post<any>(
            `${this._basePath}/auth/init`,
            { user, source: this._source },
            {
                params: { ...params, avoidAuthRedirect: true }
            }
        );
    }

    initWindowsPlatformVersion() {
        const navigatorUserAgentData = (window.navigator as any).userAgentData;
        if (
            navigatorUserAgentData &&
            navigatorUserAgentData.platform &&
            navigatorUserAgentData.platform === 'Windows'
        ) {
            navigatorUserAgentData.getHighEntropyValues(['platformVersion']).then(ua => {
                const platVerStr = ua.platformVersion.split('.')[0];
                if (platVerStr) {
                    this._majorPlatformVersion = parseInt(platVerStr, 10);
                }
            });
        }
    }

    getLoginExtraPayload(): any {
        const storedBalanzDeviceId = this._storageService.get('bdid', true);
        const uaData = new UAParser();
        const sistemaoperativo = uaData?.getOS()?.name; //Windows
        let versionso = uaData?.getOS()?.version; //10

        if (sistemaoperativo === 'Windows' && this._majorPlatformVersion > 0) {
            if (this._majorPlatformVersion >= 13) {
                versionso = '11'; // Override Windows 11 detection through  getHighEntropyValues(["platformVersion"]
            }
        }

        return {
            idDispositivo: storedBalanzDeviceId,
            TipoDispositivo: this._envService.env.TipoDispositivo,
            sc: 1,
            Nombre:
                sistemaoperativo +
                ' ' +
                versionso +
                ' ' +
                uaData?.getBrowser()?.name +
                ' ' +
                uaData.getBrowser()?.version, //Windows 10 Chrome 116.0.5845.190
            SistemaOperativo: sistemaoperativo,
            VersionSO: versionso,
            VersionAPP: VERSION.version
        };
    }

    getBlacklist$(): Observable<any> {
        return this._http.get<any>(`${this._envService.env.workerassetsUrl}/blist.json`, {
            params: { t: new Date().getTime(), avoidAuthRedirect: true }
        });
    }

    login$(user: string, pass: string, nonce: string, params): Observable<any> {
        const obs$ = this._http
            .post<any>(
                `${this._basePath}/auth/login`,
                { user, pass, nonce, source: this._source, ...this.getLoginExtraPayload() },
                {
                    params: { ...params, avoidAuthRedirect: true }
                }
            )
            .pipe(
                map(auth => {
                    this._storageService.set('auth', auth);
                    this._userSubject$.next(auth);
                    this._permissions = this.getPermissions();
                    return auth;
                })
            );
        return obs$;
    }
    gotoPreviousVersion(): Observable<any> {
        return this._http.post<any>(`${this._basePath}/auth/sso`, {});
    }

    loginSSO$(user: string, nonce: string, params): Observable<any> {
        const obs$ = this._http
            .post<any>(
                `${this._basePath}/auth/login`,
                { user, nonce, source: this._source, ...this.getLoginExtraPayload() },
                {
                    params: { ...params, avoidAuthRedirect: true }
                }
            )
            .pipe(
                map(auth => {
                    this._storageService.set('auth', auth);
                    this._userSubject$.next(auth);
                    this._permissions = this.getPermissions();
                    return auth;
                })
            );
        return obs$;
    }
    /** En caso de que se requiera hacer el logout ya habiendo dado de baja la sesión */
    localLogout() {
        const auth = this.getCurrentUser();
        if (auth) {
            this._storageService.clear();
            window.location.href = '/auth/?t=' + Date.now();
        }
    }

    logout() {
        this.silentLogout().subscribe({
            next: _ => {
                window.location.href = '/auth/?t=' + Date.now();
            },
            error: _ => {
                window.location.href = '/auth/?t=' + Date.now();
            }
        });
    }

    silentLogout(): Observable<any> {
        const url = this._basePath + '/logout';
        const auth = this.getCurrentUser();
        this._storageService.remove('modalShown');
        if (auth) {
            return this._http
                .post<any>(url, {
                    headers: new HttpHeaders({
                        Authorization: auth.AccessToken
                    })
                })
                .pipe(
                    finalize(() => {
                        this._storageService.clear();
                    })
                );
        }
    }

    getPermissionById(id) {
        return this._permissions.find(el => el.id === id);
    }

    getPermission(key) {
        const split = key.split('/');
        const path = split.slice(0, split.length - 1).join('/');
        const prop = split[split.length - 1];
        const permission = this._permissions.find(el => el.path === `/${path}`);
        const valuePermission = permission ? permission.opciones[prop] : null || acl[prop];
        return valuePermission;
    }
    getPermissionByIdPermiso(idPermiso) {
        const permiso = this._permissions.find(el => el.id === idPermiso);
        return permiso.opciones.plazoDefault;
    }
    getPermissions(): AuthPermission[] {
        if (!this._userSubject$.value) {
            return [];
        }
        return this._userSubject$.value.Permisos.find(p => p.id === 'transaccional')
            .menu.reduce((acc, el) => acc.concat(el.menu), [])
            .filter(el => el)
            .map(el => ({ id: el.id, texto: el.texto, path: el.path, opciones: el.opciones }));
    }

    hasPermission(
        permissions,
        method: 'equals' | 'includes' | 'gt' | 'st' = 'equals',
        value = 1,
        defaultPermission = false
    ): boolean {
        if (!permissions || permissions === '') {
            return false;
        }
        const split = permissions.split('/');
        let valuePermission;
        if (split.length > 1) {
            const path = split.slice(0, split.length - 1).join('/');
            const prop = split[split.length - 1];
            const permission = this._permissions.find(el => el.path === `/${path}`);
            valuePermission = permission ? permission.opciones[prop] : undefined;
        } else {
            const ACL = { ...acl, ...this._userSubject$.value.Permisos.find(p => p.id === 'transaccional').opciones };
            valuePermission = ACL[permissions];
        }
        if (!valuePermission) {
            return defaultPermission;
        }
        if (method === 'equals') {
            return parseInt(valuePermission, 10) === value;
        }
        if (method === 'includes') {
            const v = typeof value === 'string' ? parseInt(value, 10) : value;
            return valuePermission
                .toString()
                .split(',')
                .map(val => parseInt(val, 10))
                .includes(v);
        }
        if (method === 'gt') {
            return value >= parseInt(valuePermission, 10);
        }
        if (method === 'st') {
            return value <= parseInt(valuePermission, 10);
        }
        return false;
    }

    primerIngreso(idTipoDocumento, dni, email) {
        const url = this._basePath + '/usuario/solicitud';
        const paramObj = {
            idTipoDocumento,
            Documento: dni,
            Email: email,
            source: this._source
        };
        return this._http.post<any>(url, paramObj);
    }

    recuperarUsuarioClave(idTipoDocumento, dni, email) {
        const url = this._basePath + '/olvidoUsuarioClave';
        const paramObj = {
            data: {
                idTipoDocumento,
                documento: dni,
                email,
                source: this._source
            }
        };
        return this._http.post<any>(url, paramObj);
    }

    getMenu(idMenu) {
        const user: User = this.getCurrentUser();
        const permisos = user.Permisos;
        const permisosTransaccional = permisos.find(permiso => permiso.texto === 'Transaccional');
        if (!permisosTransaccional) {
            return [];
        }
        const menuTransaccional = permisosTransaccional.menu.find(menu => menu.id === idMenu);
        return menuTransaccional ? menuTransaccional.menu : [];
    }

    getDetalle() {
        const user = this._userSubject$.value;
        const idPersona = user.idPersona;
        const url = this._basePath + '/usuario/' + idPersona + '/detalle';
        return this._http.get(url);
    }

    cambiarUsuario(data: PostCambiarUsuario) {
        return this._http.post(`${this._basePath}/datos/usuario`, {
            ...data,
            user: this._userSubject$.value.user
        });
    }

    cambiarEmail(data: PostCambiarEmail) {
        return this._http.post(`${this._basePath}/datos/usuario`, {
            ...data,
            user: this._userSubject$.value.user,
            source: this._source
        });
    }

    cambiarPassword(data) {
        const user = this._userSubject$.value;
        const username = user.user;
        const url = this._basePath + '/datos/usuario';
        return this._http.post(url, { ...data, user: username }, { params: { avoidAuthRedirect: 'true' } });
    }

    cambiarCodigoOperacion(data) {
        const user = this._userSubject$.value;
        const username = user.user;
        const url = this._basePath + '/datos/usuario';
        return this._http.post(url, { ...data, user: username });
    }

    cambiarTelefono(data) {
        const user = this._userSubject$.value;
        const username = user.user;
        const url = this._basePath + '/datos/usuario';

        return this._http.post(url, { ...data, user: username });
    }

    desvincular(device) {
        return this._http.post(`${this._basePath}/datos/eliminar`, {
            campo: 'dispositivo',
            valor: device.idDispositivo
        });
    }

    comprobarDisponibilidadUsername(username) {
        const url = this._basePath + '/datos/validacionusuario';
        return this._http.post(url, { user: username });
    }

    confirmarCodigoValidacion(codigo) {
        const url = this._basePath + '/datos/confirmar';
        return this._http.post(url, { codigo });
    }

    getNivelUsuario() {
        return this._userSubject$.value?.Permisos.find(p => p.id === 'transaccional')?.opciones?.nivelUsuario;
    }

    registrar(Usuario: string, Clave: string, Nonce: string) {
        const url = this._basePath + '/usuario/registrar';
        return this._http.post<any>(url, { Usuario, Clave, Nonce });
    }

    confirmarMail(nonce: string): Observable<any> {
        const url = this._basePath + '/datos/confirmar?avoidAuthRedirect=true';
        return this._http.post<any>(url, { nonce });
    }

    cambiarclave(user: string, passNueva: string, nonce: string): Observable<any> {
        const url = this._basePath + '/cambiarclave';
        return this._http.post<any>(
            url,
            { user, passActual: null, passNueva, nonce },
            { params: { avoidAuthRedirect: 'true' } }
        );
    }

    updateAvatar(file: File): Observable<any> {
        const url = this._basePath + '/upload/avatar';

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'image/png');
        return this._http.post(url, file, { headers: headers });
    }

    resetAvatar(): Observable<any> {
        const url = this._basePath + '/usuario/eliminaravatar';
        const user = this._userSubject$.value;
        return this._http.post(url, { idPersona: user.idPersona });
    }

    operationCodeAdded(): void {
        const updatedUser = { ...this._userSubject$.value, tieneC4D: '1' };
        this._userSubject$.next(updatedUser);
        this._storageService.set('auth', updatedUser);
    }

    protegerCuenta(nonce: string): Observable<any> {
        return this._http.post<any>(`${this._basePath}/datos/protegercuenta`, { nonce });
    }

    authSSO(payload: AuthSSO): Observable<any> {
        return this._http.post<any>(`${this._basePath}/auth/sso`, payload);
    }
}
